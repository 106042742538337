import React, { useState, useEffect } from 'react';
import { db } from '../firebase.js';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import SpaceTodo from '../components/SpaceTodo';
import spaceinvaders from './assets/spaceinvaders.png'; 





const q = query(collection(db, 'spacetodos'), orderBy('timestamp', 'desc'));


function SpaceInvaderGames() {

  useEffect(() => {
    onSnapshot(q, (snapshot) => {
      setSpaceTodos(snapshot.docs.map(doc => ({
        id: doc.id,
        item: doc.data()
      })))
    })
    
  }, []);


const [spacetodos, setSpaceTodos] = useState([]);

return (
    <div>
      <div>

      </div>
      <div style={{ margin: "0rem 0rem 6rem 0rem" }}>
          <div id="games" style={{ display: 'grid', justifyContent: 'center', padding: "5rem 0rem 2rem 0rem" }}>
            <div style={{ display: 'flex', justifyContent: 'center',}}>
              <img width={"300px"} src={spaceinvaders} alt='Space Invaders' />
            </div>
            {/* <h2 style={{ display: 'flex', justifyContent: 'center', margin: "2rem 0rem 0rem 0rem", color: 'white', fontSize: '36pt', fontFamily: 'Montserrat', fontWeight: '800' }}>SPACE INVADERS</h2> */}
          </div>
          <div className="SpaceApp">
            {spacetodos.map(item => <SpaceTodo key={item.id} arr={item} />)}
          </div>
        </div>
    </div>
  
  );

}

export default SpaceInvaderGames;