import React from 'react';
import SpaceInvaderGames from '../components/SpaceInvaderGames.js';
import PacmanGames from '../components/PacmanGames.js';

const Home = () => {
  return (
    <div>
      <div>
        <h2 style={{ margin: "0rem 0rem 0rem 0rem", padding: "2rem 0rem 0.5rem 0rem", display: 'flex', justifyContent: 'center', alignItems: 'center', letterSpacing: "5px", color: 'white', fontSize: '64pt', fontStyle: "italic", fontFamily: 'Montserrat', fontWeight: '900' }}> PLAY REX</h2>
      </div>
      <div>
        <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontStyle: "italic", fontSize: '14pt', fontFamily: 'Montserrat', fontWeight: '400' }}> To play simply click on your custom game of choice in the games list below!</p>
      </div>
      <div style={{ display: "flex", padding: "0rem 0rem 0rem 0rem", color: 'white', fontSize: '24pt' }}>
        <div style={{ background: "#665EFF", display: "flex", padding: "0rem 3rem 0rem 3rem" }} >
          <SpaceInvaderGames />
        </div>
        <div style={{ background: "#FF9057", display: "flex", padding: "0rem 3rem 0rem 3rem" }}>
          <PacmanGames />
        </div>
      </div>

    </div>

  );
};

export default Home;